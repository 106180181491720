import type { AxiosResponse } from 'axios';
import axios from '@/helpers/axios';
import type { HydraMember } from '@/types/HydraTypes';
import type { VenueType } from '@/types/VenueType';
import type { VenueUnits } from '@/types/VenueTypes';

const VENUES = '/venues';

class VenueDataService {
  getAll(): Promise<HydraMember<VenueType>> {
    return axios.get(VENUES)
      .then((response: AxiosResponse) => {
        return response.data;
      });
  }

  get(id: any): Promise<VenueType> {
    return axios.get(`${VENUES}/${id}`)
      .then((response: AxiosResponse) => {
        return response.data;
      });
  }

  async create(data: any): Promise<VenueUnits> {
    return axios.post(VENUES, data);
  }

  async update(id: any, data: any): Promise<any> {
    return axios.patch(`${VENUES}/${id}`, data);
  }

  async saveProfilePictureData(id: string, data: { profileImage: string }) {
    return axios.patch(`${VENUES}/${id}`, data)
      .then((response: AxiosResponse) => {
        return response.data;
      });
  }

  delete(id: any): Promise<any> {
    return axios.delete(`${VENUES}/${id}`);
  }
}

export default new VenueDataService();
