import type { AxiosResponse } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { defineStore } from 'pinia';
import { lastValueFrom, of } from 'rxjs';
import { computed, ref } from 'vue';
import { router } from '@/plugins/1.router';
import AuthService from '@/services/AuthService';
import VenueService from '@/services/VenueService';
import type { LoginResponseType } from '@/types/auth/LoginResponseType';
import type { VenueType } from '@/types/VenueType';

export const useUserStore = defineStore('user', () => {
  const refreshToken = ref(localStorage.getItem('refresh_token'));
  const token = ref(localStorage.getItem('authorization'));
  const user = ref(token.value ? jwtDecode(token.value) : null);
  const isLoggedIn = computed(() => !(user.value === null));
  const currentVenue = ref(JSON.parse(localStorage.getItem('currentVenue')));
  const venues = ref(JSON.parse(localStorage.getItem('venues')));

  function login(username: string, password: string) {
    return AuthService.login(username, password).then((response: AxiosResponse) => {
      if (response?.status === 200) {
        const data: LoginResponseType = response.data;

        refreshToken.value = data.refresh_token;
        token.value = data.token;
        localStorage.setItem('refresh_token', data.refresh_token);
        localStorage.setItem('authorization', data.token);
        user.value = jwtDecode(data.token);
        loadVenues().then(() => {
          router.push({ name: 'index' });
        });
      }
      else {
        return response;
      }
    });
  }

  async function resetPassword(email: string) {
    return AuthService.resetPassword(email);
  }

  async function restorePassword(token: string, password: string) {
    return AuthService.setPassword(token, password);
  }

  async function loadTokenByRefreshToken(): Promise<string | null> {
    if (refreshToken.value === null)
      return lastValueFrom(of(null));

    return AuthService.refreshToken(refreshToken.value).then((data: LoginResponseType) => {
      refreshToken.value = data.refresh_token;
      token.value = data.token;
      localStorage.setItem('refresh_token', data.refresh_token);
      localStorage.setItem('authorization', data.token);
      user.value = jwtDecode(data.token);

      return token.value;
    });
  }

  function logout() {
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('authorization');
    user.value = null;
    refreshToken.value = null;
    token.value = null;
    router.push({ name: 'Login' });
  }

  function loadVenues() {
    return VenueService.getAll().then(data => {
      localStorage.setItem('venues', JSON.stringify(data['hydra:member']));
      venues.value = data['hydra:member'];

      if (currentVenue.value === null) {
        localStorage.setItem('currentVenue', JSON.stringify(venues.value[0]));
        currentVenue.value = venues.value[0];
      }
      else {
        if (!venues.value.some(obj => obj.id, currentVenue.value.id)) {
          localStorage.setItem('currentVenue', JSON.stringify(venues.value[0]));
          currentVenue.value = venues.value[0];
        }
      }
    });
  }

  function setCurrentVenue(venue: VenueType) {
    localStorage.setItem('currentVenue', JSON.stringify(venue));
    currentVenue.value = ref(JSON.parse(localStorage.getItem('currentVenue')));
  }

  function isAdmin(): boolean {
    return user.value?.roles.includes('ROLE_ADMIN');
  }

  return { refreshToken, token, user, isLoggedIn, resetPassword, loadTokenByRefreshToken, restorePassword, login, logout, currentVenue, venues, loadVenues, setCurrentVenue, isAdmin };
});
