<script setup lang="ts">
import { useTheme } from 'vuetify';
import ScrollToTop from '@core/components/ScrollToTop.vue';
import initCore from '@core/initCore';
import { initConfigStore, useConfigStore } from '@core/stores/config';
import { hexToRgb } from '@layouts/utils';

const { global } = useTheme();

// ℹ️ Sync current theme with initial loader theme
initCore();
initConfigStore();

const configStore = useConfigStore();
</script>

<template>
  <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
    <Component :is="$route.meta.layout || 'div'">
      <RouterView />
    </Component>
    <ScrollToTop />
  </VApp>
</template>
