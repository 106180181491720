import type { App } from 'vue';
import type { PluginOptions } from 'vue-toastification';
import Toast, { POSITION } from 'vue-toastification';

import 'vue-toastification/dist/index.css';

const toastOptions: PluginOptions = {
  position: POSITION.BOTTOM_RIGHT,
  maxToasts: 5,
  pauseOnFocusLoss: false,
};

export default function (app: App) {
  app.use(Toast, toastOptions);
}
