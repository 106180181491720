// Styles
import { cookieRef } from '@/@layouts/stores/config';
import '@core/scss/template/libs/vuetify/index.scss';
import { deepMerge } from '@antfu/utils';
import type { App } from 'vue';
import { createVuetify } from 'vuetify';
import { VBtn } from 'vuetify/components/VBtn';
import { VDateInput } from 'vuetify/labs/VDateInput';
import { VTimePicker } from 'vuetify/labs/VTimePicker';
import { cs, en } from 'vuetify/locale';
import defaults from './defaults';
import { icons } from './icons';
import { processColorCode, staticPrimaryColor, staticPrimaryDarkenColor, staticVenuePrimaryColor, themes } from './theme';
import { themeConfig } from '@themeConfig';
import 'vuetify/styles';

export default function (app: App) {
  const cookieThemeValues = {
    defaultTheme: resolveVuetifyTheme(themeConfig.app.theme),
    themes: {
      light: {
        colors: {
          'primary': cookieRef('lightThemePrimaryColor', processColorCode(staticVenuePrimaryColor, staticPrimaryColor)).value,
          'primary-darken-1': cookieRef('lightThemePrimaryDarkenColor', processColorCode(staticVenuePrimaryColor, staticPrimaryDarkenColor)).value,
        },
      },
      dark: {
        colors: {
          'primary': cookieRef('darkThemePrimaryColor', processColorCode(staticVenuePrimaryColor, staticPrimaryColor)).value,
          'primary-darken-1': cookieRef('darkThemePrimaryDarkenColor', processColorCode(staticVenuePrimaryColor, staticPrimaryDarkenColor)).value,
        },
      },
    },
  };

  const optionTheme = deepMerge({ themes }, cookieThemeValues);

  const vuetify = createVuetify({
    aliases: {
      IconBtn: VBtn,
    },
    components: {
      VDateInput,
      VTimePicker,
    },
    locale: {
      locale: 'cs',
      fallback: 'en',
      messages: { cs, en },
    },
    defaults,
    icons,
    theme: optionTheme,
  });

  app.use(vuetify);
}
