import * as Sentry from '@sentry/vue';
import type { App } from 'vue';

export default function (app: App) {
  if (import.meta.env.VITE_SENTRY_ENABLED == 0)
    return;

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: import.meta.env.VITE_SENTRY_RELEASE,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],

    // Performance Monitoring
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACE_SAMPLE_RATE, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/api\.egida\.pslabs\.sk\/api/],

    // Session Replay
    replaysSessionSampleRate: import.meta.VITE_SENTRY_REPLACE_SESSION_SAMPLE_RATE, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    profilesSampleRate: 1.0,
  });
}
