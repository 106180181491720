import type { App } from 'vue';
import type { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import blankLayout from '@/layouts/blank.vue';
import defaultLayout from '@/layouts/default.vue';
import { useUserStore } from '@/stores/user';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'index',
    redirect: to => {
      return { name: 'accommodation' };
    },
    meta: { layout: defaultLayout, adminAccess: false },
  },
  {
    path: '/accommodation',
    name: 'accommodation',
    component: () => import('/src/views/AccommodationsView.vue'),
    meta: { layout: defaultLayout, adminAccess: true },
  },
  {
    path: '/accommodation/:id',
    name: 'accommodationDetail',
    component: () => import('/src/views/AccommodationsDetailView.vue'),
    meta: { layout: defaultLayout, navActiveLink: 'accommodation', adminAccess: true },
  },
  {
    path: '/events',
    name: 'events',
    component: () => import('/src/views/EventsView.vue'),
    meta: { layout: defaultLayout, adminAccess: false },
  },
  {
    path: '/events/:id',
    name: 'eventDetail',
    component: () => import('/src/views/EventDetailView.vue'),
    meta: { layout: defaultLayout, adminAccess: false, navActiveLink: 'events' },
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('/src/views/CalendarView.vue'),
    meta: { layout: defaultLayout, adminAccess: false },
  },
  {
    path: '/accommodation-units',
    name: 'accommodation-units',
    component: () => import('/src/views/AccommodationsUnitsView.vue'),
    meta: { layout: defaultLayout, adminAccess: false },
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import('/src/views/CustomersView.vue'),
    meta: { layout: defaultLayout, adminAccess: false },
  },
  {
    path: '/customers/:id',
    name: 'customersDetail',
    component: () => import('/src/views/CustomerDetailView.vue'),
    meta: { layout: defaultLayout, navActiveLink: 'customers', adminAccess: false },
  },
  {
    path: '/payers',
    name: 'payers',
    component: () => import('/src/views/PayersView.vue'),
    meta: { layout: defaultLayout, adminAccess: false },
  },
  {
    path: '/payers/:id',
    name: 'payersDetail',
    component: () => import('/src/views/PayersDetailView.vue'),
    meta: { layout: defaultLayout, navActiveLink: 'payers', adminAccess: false },
  },
  {
    path: '/partners',
    name: 'partners',
    component: () => import('/src/views/PartnersView.vue'),
    meta: { layout: defaultLayout, adminAccess: false },
  },
  {
    path: '/admin-management',
    name: 'admin-management',
    component: () => import('/src/views/AdminManagementView.vue'),
    meta: { layout: defaultLayout, adminAccess: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('/src/views/auth/LoginView.vue'),
    meta: { layout: blankLayout, public: true },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('/src/views/auth/ForgotPasswordView.vue'),
    meta: { layout: blankLayout, public: true },
  },
  {
    path: '/restore-password/:token',
    name: 'ResetPassword',
    component: () => import('/src/views/auth/ResetPasswordView.vue'),
    meta: { layout: blankLayout, public: true },
  },
  {
    path: '/settings/currencies',
    name: 'CurrencySettings',
    component: () => import('/src/views/settings/CurrenciesView.vue'),
    meta: { layout: defaultLayout, adminAccess: true },
  },
  {
    path: '/settings/languages',
    name: 'LanguageSettings',
    component: () => import('/src/views/settings/LanguagesView.vue'),
    meta: { layout: defaultLayout, adminAccess: true },
  },
  {
    path: '/settings/countries',
    name: 'CountrySettings',
    component: () => import('/src/views/settings/CountriesView.vue'),
    meta: { layout: defaultLayout, adminAccess: true },
  },
  {
    path: '/settings/venues',
    meta: { layout: defaultLayout, adminAccess: true },
    children: [
      {
        path: '',
        strict: true,
        name: 'VenueSettings',
        component: () => import('/src/views/settings/VenuesView.vue'),
        meta: { layout: defaultLayout, adminAccess: true },
      },
      {
        path: ':id/:tab?',
        name: 'VenueDetailSettings',
        component: () => import('/src/views/settings/VenuesDetailView.vue'),
        meta: { layout: defaultLayout, navActiveLink: 'VenueSettings', adminAccess: true },
        children: [
          {
            path: '',
            strict: true,
            name: 'VenueDetail',
            meta: { layout: defaultLayout, navActiveLink: 'VenueSettings', adminAccess: true },
            redirect(to) {
              return { name: 'VenueDetailSettings', params: { tab: to.params.tab ?? 'general', id: to.params.id } };
            },
          },
        ],
      },
    ],
  },
  {
    path: '/profile/:tab?',
    name: 'Profile',
    meta: { layout: defaultLayout, adminAccess: false },
    component: () => import('/src/views/profile/UserProfileView.vue'),
    children: [
      {
        path: '',
        strict: true,
        name: 'Profile Overview',
        redirect(to) {
          return { name: 'Profile', params: { tab: to.params.tab ?? 'overview' } };
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async to => {
  const userStore = useUserStore();

  if ((userStore.token === null || userStore.token === 'undefined') && userStore.refreshToken !== null && to.name !== 'Login')
    await userStore.loadTokenByRefreshToken();

  if ((userStore.refreshToken === null || userStore.refreshToken === 'undefined') && !to.meta.public) {
    await userStore.logout();

    return { name: 'Login' };
  }

  const isLoggedIn = userStore.isLoggedIn;
  if (!isLoggedIn && !to.meta.public && to.name !== 'Login')
    return { name: 'Login' };

  if ((userStore.currentVenue === null || userStore.currentVenue === 'undefined') && to.name !== 'SelectVenue') {
    userStore.loadVenues().then(() => {
      location.reload();
    });
  }

  if (!userStore.isAdmin() && to.meta.adminAccess)
    return { name: 'index' };

  /*

  ℹ️ Commented code is legacy code

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    // ℹ️ Only add `to` query param if `to` route is not index route
    if (!isLoggedIn)
      return next({ name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn)
    next('/')

  return next()

  */

// if (canNavigate(to)) {
//   if (to.meta.redirectIfLoggedIn && isLoggedIn)
//     return '/'
// }
// else {
//   if (isLoggedIn)
//     return { name: 'not-authorized' }
//   else
//     return { name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } }
// }
});

export { router };
export default function (app: App) {
  app.use(router);
}
